
  import userController from '/home/builduser/agent00/work/5d9827cfe03d3bf1/right-click-protect/src/components/RightClickProtect/controller.ts';
  import createControllerWrapper from 'yoshi-flow-editor-runtime/build/esm/controllerWrapper.js';
  import {
    
  } from 'yoshi-flow-editor-runtime/build/esm/controller/httpClientProp';

  var sentryConfig = {
    DSN: 'https://261938f6d0c848dfb768387e4cb17694@sentry.wixpress.com/1142',
    id: '261938f6d0c848dfb768387e4cb17694',
    projectName: 'right-click-protect',
    teamName: 'photography',
  };

  var experimentsConfig = {"scope":"test"};

  var translationsConfig = {"default":"en","defaultTranslationsPath":"/home/builduser/agent00/work/5d9827cfe03d3bf1/right-click-protect/src/assets/locales/messages_en.json"};

  var biConfig = {"owner":"@wix/bi-logger-editor-flow-template","visitor":"@wix/bi-logger-editor-flow-template","enableUniversalEvents":false};

  var defaultTranslations = {"Rcp_Settings_Default_Rcp_Text":"© Copyright","Rcp_Settings_Default_Title_Text":"© Copyright","Rcp_Settings_Info_Text":"Protect your content* with a custom message that appears each time visitors right-click on your content.","Rcp_Settings_Info_Notice":"*Visitors will still be able to use print-screen or other methods to save your content.","Rcp_Settings_Panel_Menu_Info":"Info","Rcp_Settings_Panel_Menu_Button":"Edit Your Message","Rcp_Settings_Panel_Menu_Settings":"Settings","Rcp_Settings_Settings_Title":"Your message will appear on the footer & when users right click.","Rcp_Settings_Settings_Teaser_Title":"Edit your message","Rcp_Settings_Settings_Copyrights_Label":"Copyrights","Rcp_Settings_Settings_Registered_Label":"Registered","Rcp_Settings_Settings_Trademark_Label":"Trademark","Rcp_Settings_Settings_Text_Label":"Edit your message:","Rcp_Settings_Panel_Menu_Design":"Design","Rcp_Settings_Design_Title":"Footer Text","Rcp_Settings_Design_Font_Label":"Customize Text","Rcp_Settings_Design_Color_Label":"Font Color","Rcp_Settings_Close":"Close","Rcp_Settings_Info_Old_Editor":"Right-Click Protect cuts down on unwanted downloads* of your content. This app uses advanced controls that are only supported in the new editor.","Rcp_Settings_Info_Old_Editor_link":"Switching to the new editor","Dorothy_Oz":"You got it! Keep going. A) You’re almost a winner, the end’s not in doubt. Here comes one last clue, so get ready to shout B) X marks the spot."};

  import biLogger from '/home/builduser/agent00/work/5d9827cfe03d3bf1/right-click-protect/node_modules/@wix/bi-logger-editor-flow-template/dist/src/index.js';

  const _controller = createControllerWrapper(userController, {
    sentryConfig,
    biConfig,
    experimentsConfig,
    biLogger,
    translationsConfig,
    appName: "Right Click Protect",
    appDefinitionId: "1429e204-2254-7f40-836b-5ffd769ac4c0",
    componentId: "1429e225-f6b0-2230-82f6-9a6aa1192f8f",
    projectName: "right-click-protect",
    defaultTranslations,
  }, {
     
  });
  export const controller = _controller;
  export default _controller;
