import { CreateControllerFn } from 'yoshi-flow-editor-runtime';

const createController: CreateControllerFn = async ({ flowAPI, controllerConfig }) => {
  // For more info about controller structure,
  // check the docs: https://bo.wix.com/pages/yoshi/docs/editor-flow/structure-api/component#controller
  return {
    async pageReady() {
      const experiments = await flowAPI.getExperiments();
      // Gettings the old RCP config (2016), if it doesnt exist this app was created with the new settings for rcp
      const data = flowAPI.controllerConfig.config.publicData.APP;
      const rcpString = data.rcp;
      let rcpConfig = rcpString && JSON.parse(decodeURIComponent(rcpString));
      if (!rcpConfig) {
        rcpConfig = null;
      }
      controllerConfig.setProps({
        rcpConfig,
      });
    },
  };
};

export default createController;
